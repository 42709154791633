import React from 'react';
import Typography from 'src/components/core/Typography';
import Faq, { FaqItem } from 'src/components/Faq';
import SectionContainer from 'src/sections/shared/SectionContainer';

const pricingFaqs: FaqItem[] = [
  {
    question: 'What payment methods do you accept?',
    answer: 'We accept all major credit and debit cards.',
  },
  {
    question: 'Do you have a refund policy?',
    answer: 'Yes, we have a 100% satisfaction guarantee. No questions asked. Let us know within 30 days of starting a new plan or add-on and we\'ll provide a complete refund to you.',
  },
  {
    question: 'Do I have to sign a contract?',
    answer: "No, Bizwise is no commitment. Cancel at any time for any reason if you're not absolutely thrilled. We believe that everything should be as low-friction as possible. However, if you or your business need a contract, we'll provide one.",
  },
  {
    question: 'Can I upgrade or downgrade?',
    answer: 'Absolutely, pay for only what you use and you\'re always free to upgrade or downgrade.',
  },
  {
    question: 'When am I billed?',
    answer: 'You\'re billed on your sign up date and every month on the anniversary of your sign up date.',
  },
  {
    question: 'What if the size of our company changes?',
    answer: 'With Bizwise, you\'re only charged for active employees. And if you have seasonal team members or employees that aren\'t working in a given month you won\'t be charged for their accounts. When your team grows you\'ll be able to seamlessly add more accounts. It\'s a win-win for you.',
  },
  {
    question: 'Is it easy to switch to Bizwise if I am using other providers?',
    answer: 'We make switching stress & worry free. We offer white glove migration service for all Premium & Elite customers.',
  },
  {
    question: 'What type of security or privacy do you offer?',
    answer: 'We take your security seriously. We\'ll never sell your data & we offer full encryption, SSL security, & PCI compliance.',
  },
];

const freePlanFaqs: FaqItem[] = [
  {
    question: 'Is it really free?',
    answer: 'Yes, this is not a free trial. It\'s free forever.',
  },
  {
    question: 'How can you afford a free forever plan? Do you sell data?',
    answer: 'No, we do not sell your data. And we never will. We offer the free forever plan as a way for you to get started with your business. As your business grows you\'ll need more apps and features. At that point it will make sense for you to subscribe to a paid plan.',
  },
  {
    question: 'So will l be able to upgrade from the free forever plan to a paid plan whenever I need to?',
    answer: 'Absolutely, as your business grows you\'re able to upgrade and get exactly what you need, exactly when you need it.',
  },
];

const accountMigrationFaqs: FaqItem[] = [
  {
    question: 'What is an account migration?',
    answer: 'Account migration is a free service offered by the Bizwise team. We\'ll take the files & materials you give us (website, logo, branding, schedules, pricing sheets, product & service descriptions), and make sure they\'re set up in your account, so your business is set up for success.',
  },
  {
    question: 'What is included?',
    answer: 'The Bizwise team will happily transfer your existing contracts, customer list, questionnaires, logo\'s, branding, and pricing/service lists. You can upload anything and we\'ll get to work ensuring it\'s compatible and accessible within your Bizwise account.',
  },
  {
    question: 'How do I upload my materials?',
    answer: 'In Bizwise, we\'ll guide you through a simple account migration uploader. From there, you\'ll be able to upload and submit your materials. Additionally, our support team is happy to collect your materials 1:1 to ensure a smooth handoff process.',
  },
  {
    question: 'What upload formats do you accept?',
    answer: 'We accept: .docs, .pdf, .jpg, .png, mp4, mp3, .mov, .xls, Google docs (did you know you can download them?), screen-shots, photos of post-its — whatever you\'ve got that we can read.',
  },
  {
    question: 'How long does the migration process take?',
    answer: 'The account migration is usually completed within a day of uploading. However, in some cases it can take up to a week. Most businesses are up and migrated the same day they submit their materials.',
  },
  {
    question: 'Will I experience any interruptions in my business?',
    answer: 'There will be no interruptions in your website, business, or workflow while we migrate your business from your existing provider to Bizwise.',
  },
  {
    question: 'Can I do it myself?',
    answer: 'Yes! We\'ve built Bizwise to be user-friendly for business owners just like you. The free account migration is simply an added benefit to take some of the burden off your plate. Any Bizwise customer is able to set things up themselves. Our guides, video tutorials, and customer success team will always be on standby should you have any questions.',
  },
];

const emailModuleFaqs: FaqItem[] = [
  {
    question: 'Can I link my email with my mybusiness.com domain?',
    answer: 'Yes! We are able to set up Bizwise email on any domain managed by Bizwise.',
  },
  {
    question: 'I already have an existing email account, can I migrate it?',
    answer: 'Yes! Our support staff will assist you in migrating your email account to Bizwise email.',
  },
  {
    question: 'How long does it take to migrate to Bizwise mail?',
    answer: 'Our support staff can onboard you to Bizwise mail and migrate your existing account within 1 day.',
  },
  {
    question: 'Will it work on my mobile device?',
    answer: 'Bizwise mail can be accessed from any standard mobile email client, such as iMail and Gmail.',
  },
  {
    question: 'I am not tech savvy, will I be able to use it?',
    answer: 'Yes. The email module was designed around simplicity and usability for small business owners.',
  },
  {
    question: 'I use email forwarding and autoresponders. Do you have those features?',
    answer: 'Yes! The email module includes all basic functionality offered by other common email providers, such as Gmail and Outlook.',
  },
  {
    question: 'Are emails sent securely and privately?',
    answer: 'Bizwise email is built on top of Gmail\'s backend, and all emails are stored on Google\'s secure servers.',
  },
];

const customerListModuleFaqs: FaqItem[] = [
  {
    question: 'Why should I use the Customer List app?',
    answer: 'The Customer List app will help streamline your interaction with customers by keeping track of all their information in a single place.',
  },
  {
    question: 'Is it really free?',
    answer: 'Yes! The Customer List is and will always be free.',
  },
  {
    question: 'Can I move from a different system to Bizwise?',
    answer: 'Yes! Our support team will assist you in migrating your customer data from any other customer management system to Bizwise.',
  },
  {
    question: 'What\'s the difference between your tool and other client management apps?',
    answer: 'Unlike most common client management apps, Bizwise\'s customer list is built for small business owners like you.',
  },
  {
    question: 'I\'m not tech savvy, can I still use it?',
    answer: 'Yes! We designed the Customer List app to be easy to use by small business owners, not for IT personnel.',
  },
  {
    question: 'Does it integrate with my bookkeeping software?',
    answer: 'Yes! Our support team will help set up your customer list app to work with most bookeeping platforms.',
  },
];

const teamsModuleFaqs: FaqItem[] = [
  {
    question: 'How do I invite team members to use the Teams module?',
    answer: 'Inviting team members is easy—just send an email invite, and they can set up their account with a simple step.',
  },
  {
    question: 'How do employees access the Bizwise tools?',
    answer: 'Employees can access all Bizwise tools, including website editing, professional email, and customer lists, through the Teams desktop module.',
  },
  {
    question: 'Can I customize tool access for different employees?',
    answer: 'Yes, you can tailor access permissions based on employee roles.',
  },
  {
    question: 'Is employee information stored securely?',
    answer: 'Absolutely. All employee data is securely stored, with easy access only for authorized personnel.',
  },
  {
    question: 'What happens if an employee leaves the company?',
    answer: 'Access to Bizwise tools can be revoked at any time by the business admin inside the desktop app, ensuring former employees no longer have access to company resources.',
  },
  {
    question: 'How does the Teams module enhance productivity?',
    answer: 'By centralizing access to essential tools and organizing employee information, the Teams module streamlines workflows and boosts team productivity.',
  },
];

const adsModuleFaqs: FaqItem[] = [
  {
    question: 'What does your Google Ads management service include?',
    answer: 'Our service covers everything from campaign creation to ongoing optimization, including keyword research, ad placement, and performance tracking.',
  },
  {
    question: 'How do you ensure my ads reach the right audience?',
    answer: 'We use targeted keywords and AI-powered tools to ensure your ads reach potential customers using messaging that\u2019s most likely to make the sale. ',
  },
  {
    question: 'Can I see the results of my ad campaigns?',
    answer: 'Yes, we provide detailed monthly reports that include data-driven insights and specific ad strategies to keep you informed.',
  },
  {
    question: 'What\u2019s the minimum ad budget required?',
    answer: 'We work with ad budgets starting as low as $10 per month, with flexible options to scale as your business grows.',
  },
  {
    question: 'How do you optimize my ad campaigns?',
    answer: 'We continuously monitor and replace any underperforming assets, ensuring they stay effective and deliver the best results.',
  },
  {
    question: 'Do I have control over my ad campaigns?',
    answer: 'Absolutely. While we manage and optimize your ads, you have full control and final approval on all campaign decisions. You can reach us anytime over email, phone, or live chat.',
  },
];

const socialModuleFaqs: FaqItem[] = [
  {
    question: 'How does your team create content for my social media pages?',
    answer: 'We generate content by aligning it with your business\'s website and brand identity, often posting about specific services or products from your website, ensuring consistency and engagement across all posts.',
  },
  {
    question: 'Can I review and approve posts before they go live?',
    answer: 'Yes, you\'ll have the opportunity to review and approve each post to ensure it meets your standards.',
  },
  {
    question: 'What social media platforms do you manage?',
    answer: 'We specialize in managing Facebook and Instagram, covering everything from content creation to posting. We can also support other less popular platforms, just let us know!',
  },
  {
    question: 'How often will my social media be updated?',
    answer: 'We offer flexible posting frequencies that can be customized based on your needs, whether it\'s daily, weekly, or monthly.',
  },
  {
    question: 'How does this service help my business grow?',
    answer: 'Our service enhances your online presence, attracting new followers, boosting sales, and building customer loyalty through consistent and engaging content.',
  },
  {
    question: 'What is the cost of your social media management services?',
    answer: 'Pricing starts at $30 per month and varies depending on the level of content creation, visuals, and posting frequency that best fits your business.',
  },
  {
    question: 'Can I post videos too?',
    answer: 'Yes, absolutely! Just send us the video, and we\'ll incorporate it into your social media strategy.',
  },
];

export enum FaqTypes {
  PRICING,
  FREE_FOREVER,
  ACCOUNT_MIGRATION,
  EMAIL_MODULE,
  CUSTOMER_LIST_MODULE,
  TEAMS_MODULE,
  ADS_MODULE,
  SOCIAL_MODULE,
}

interface Props {
  types: FaqTypes[],
}

const FaqSection = React.forwardRef<HTMLDivElement, Props>(({ types }, ref) => (
  <SectionContainer
    ref={ref}
    className="flex flex-col gap-10 desktop:gap-20"
    backgroundShape={4}
    backgroundShapeBlendMode="soft-light"
  >
    {types.map((type, i) => {
      let title = 'Frequently Asked Questions';
      let items: FaqItem[] = [];
      switch (type) {
        case FaqTypes.PRICING: {
          items = pricingFaqs;
          break;
        }
        case FaqTypes.FREE_FOREVER: {
          title = 'Free Forever Plan FAQs';
          items = freePlanFaqs;
          break;
        }
        case FaqTypes.ACCOUNT_MIGRATION: {
          items = accountMigrationFaqs;
          break;
        }
        case FaqTypes.EMAIL_MODULE: {
          items = emailModuleFaqs;
          break;
        }
        case FaqTypes.CUSTOMER_LIST_MODULE: {
          items = customerListModuleFaqs;
          break;
        }
        case FaqTypes.TEAMS_MODULE: {
          items = teamsModuleFaqs;
          break;
        }
        case FaqTypes.ADS_MODULE: {
          items = adsModuleFaqs;
          break;
        }
        case FaqTypes.SOCIAL_MODULE: {
          items = socialModuleFaqs;
          break;
        }
        default: {
          break;
        }
      }
      return (
        // eslint-disable-next-line react/no-array-index-key
        <div key={i}>
          <Typography
            variant="h2"
            color="blue"
            centered
            underline={i === 0}
          >
            {title}
          </Typography>
          <Faq faqs={items} className="mt-10" />
        </div>
      );
    })}
  </SectionContainer>
));

export default FaqSection;
