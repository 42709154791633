import React from 'react';
import { createUseStyles } from 'react-jss';
import { breakpoint } from 'src/theme';
import { StaticImage } from 'gatsby-plugin-image';
import RightLeftChecklistSection from '../shared/RightLeftChecklist';

const useStyles = createUseStyles({
  firstMedia: {
    width: '90%',
    marginLeft: '5%',
    [breakpoint.down('sm')]: {
      width: '95%',
    },
  },
});

const ChecklistSection: React.FC = () => {
  const classes = useStyles();
  return (
    <RightLeftChecklistSection
      rightContent={{
        title: 'Our team of experts will set up your templates - carefully by hand',
        checklist: [
          'Save time on data entry',
          'Stay focused while we make the switch',
          'Seamlessly transition to Bizwise',
          'Skip the stress',
        ],
        media: (
          <StaticImage
            width={600}
            src="https://assets.bizwise.com/landing-ui/sections/22.jpg"
            alt="Four people sharing a laptop"
            className={classes.firstMedia}
            style={{
              borderRadius: 20,
              filter: 'drop-shadow(0px 25px 50px rgba(0, 0, 0, 0.25))',
            }}
          />
        ),
      }}
      leftContent={{
        title: 'Do-it-yourself, have no fear',
        checklist: [
          'Get best practices for using your templates',
          'Ask for help if you need it, we\'ll be there',
          'Have full control at your preferred pace',
          'Stay in total control',
        ],
        media: (
          <StaticImage
            width={600}
            src="https://assets.bizwise.com/landing-ui/sections/23.png"
            alt="Man smiling on phone with laptop"
            style={{
              borderRadius: 20,
              filter: 'drop-shadow(0px 25px 50px rgba(0, 0, 0, 0.25))',
            }}
          />
        ),
      }}
    />
  );
};

export default ChecklistSection;
