import React from 'react';
import { createUseStyles } from 'react-jss';
import { pxToRem, spacing, breakpoint } from 'src/theme';
import { assetLink } from 'src/utils';
import Typography from 'src/components/core/Typography';
import Button from 'src/components/core/Button';
import NavbarSpacer from 'src/components/Navbar/Spacer';
import SectionContainer from 'src/sections/shared/SectionContainer';

const useStyles = createUseStyles({
  root: {
    background: 'linear-gradient(105.37deg, #13266D 16.05%, #5FBCFF 97.02%)',
    position: 'relative',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& > *': {
      zIndex: 1,
      position: 'relative',
      width: '50%',
      [breakpoint.down('md')]: {
        width: '100%',
      },
    },
    [breakpoint.down('md')]: {
      flexDirection: 'column-reverse',
    },
  },
  shape: {
    position: 'absolute',
    height: '100%',
    top: 0,
    left: 0,
    mixBlendMode: 'overlay',
    pointerEvents: 'none',
    [breakpoint.mobile()]: {
      display: 'none',
    },
  },
  button: {
    minWidth: pxToRem(180),
    [breakpoint.down('sm')]: {
      width: '100%',
    },
  },
  textContainer: {
    [breakpoint.up('md')]: {
      marginTop: '-3vh', // offset the vertical padding
    },
    [breakpoint.down('md')]: {
      // layout shifts to vertical stacking
      marginTop: spacing(5),
    },
  },
  subtitle: {
    marginBottom: spacing(7.5),
    fontSize: pxToRem(22),
    fontWeight: 400,
    lineHeight: pxToRem(34),
  },
});

const Hero: React.FC = () => {
  const classes = useStyles();
  return (
    <SectionContainer className={classes.root}>
      <img src={assetLink('landing-ui/background-shapes/9.svg')} alt="" className={classes.shape} />
      <NavbarSpacer />
      <div className={classes.row}>
        <div className={classes.textContainer}>
          <div className="mx-0 md:mx-auto" style={{ maxWidth: pxToRem(552) }}>
            <Typography variant="h1" color="white" className="mb-5">
              Free Account Migration Makes Switching Easy
            </Typography>
            <Typography variant="p1" componentOverride="h2" color="white" className="mb-7">
              Wanting to upgrade your business but worry about the transition process?
              {' '}
              Have no fear, we&apos;ll handle it for you end to end.
            </Typography>
            <Button
              variant="filled"
              color="teal"
              href="/register/"
              className={classes.button}
            >
              Get Started
            </Button>
            <Typography variant="p3" color="white" className="my-4">
              No credit card required.
            </Typography>
          </div>
        </div>
        <div>
          <img src={assetLink('landing-ui/heroes/6.svg')} alt="" style={{ pointerEvents: 'none' }} />
        </div>
      </div>
    </SectionContainer>
  );
};

export default Hero;
