import React from 'react';
import Layout from 'src/layouts/Page';
import Seo from 'src/components/Seo';
import ApiWrapper from 'src/components/ApiWrapper';
import HeroSection from 'src/sections/Migrate/Hero';
import GetStartedSection from 'src/sections/Migrate/GetStarted';
import ChecklistSection from 'src/sections/Migrate/Checklist';
import HowItWorksSection from 'src/sections/Migrate/HowItWorks';
import FaqSection, { FaqTypes } from 'src/sections/shared/Faq';

const MigratePage: React.FC = () => (
  <ApiWrapper apiServices={['home-api']}>
    <Layout>
      <Seo title="Free Account Migration - Bizwise" />
      <HeroSection />
      <HowItWorksSection />
      <ChecklistSection />
      <GetStartedSection />
      <FaqSection types={[FaqTypes.ACCOUNT_MIGRATION]} />
    </Layout>
  </ApiWrapper>
);

export default MigratePage;
