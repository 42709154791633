import React from 'react';
import { createUseStyles } from 'react-jss';
import { PlusIcon, MinusIcon } from '@heroicons/react/solid';
import { pxToRem, spacing, Theme } from 'src/theme';
import Typography from '../core/Typography';
import useQuestion from './utils';

const useStyles = createUseStyles((theme: Theme) => ({
  answer: {
    overflow: 'hidden',
    height: 0,
    transition: 'height 200ms ease-in-out',
  },
  questionBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    textAlign: 'left',
  },
  separator: {
    borderColor: theme.palette.secondary.HR,
    marginTop: spacing(4),
    marginBottom: spacing(4),
  },
  expandIcon: {
    fontWeight: 600,
    width: pxToRem(24),
    height: pxToRem(24),
    minWidth: pxToRem(24),
    minHeight: pxToRem(24),
    color: theme.palette.primary.BLUE,
  },
}));

interface Props {
  question: string,
  answer: string,
}

const Question: React.FC<Props> = ({ question, answer }) => {
  const {
    isExpanded,
    style,
    accordionRef,
    expandAnswer,
  } = useQuestion();
  const classes = useStyles();
  return (
    <div>
      <button type="button" className={classes.questionBar} onClick={() => expandAnswer()}>
        <Typography variant="p1" color="blue" style={{ fontWeight: 600 }}>
          {question}
        </Typography>
        {isExpanded ? (
          <MinusIcon className={classes.expandIcon} />
        ) : (
          <PlusIcon className={classes.expandIcon} />
        )}
      </button>
      <div ref={accordionRef} className={classes.answer} style={style}>
        <Typography variant="p3" color="blackish" style={{ paddingTop: spacing(6) }}>
          {answer}
        </Typography>
      </div>
      <hr className={classes.separator} />
    </div>
  );
};

export default Question;
