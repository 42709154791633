import React from 'react';
import Question from './Question';

export interface FaqItem {
  question: string,
  answer: string,
}

interface Props {
  className?: string,
  faqs: FaqItem[],
}

const Faq: React.FC<Props> = ({
  className,
  faqs,
}) => (
  <div className={className}>
    {faqs.map(({ question, answer }) => (
      <Question question={question} answer={answer} key={question} />
    ))}
  </div>
);

export default Faq;
