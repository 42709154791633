import React from 'react';
import cx from 'clsx';
import { createUseStyles } from 'react-jss';
import { spacing, breakpoint, pxToRem } from 'src/theme';
import Typography from 'src/components/core/Typography';
import SectionContainer from '../shared/SectionContainer';

const useStyles = createUseStyles({
  container: {
    background: '#F7F7F7',
  },
  grid: {
    display: 'grid',
    justifyContent: 'center',
    columnGap: spacing(10),
    rowGap: spacing(10),
    marginTop: spacing(15),
    gridTemplateColumns: `repeat(3, minmax(0, ${pxToRem(420)}))`,
    [breakpoint.down('sm')]: {
      gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
      marginTop: spacing(10),
    },
  },
  icon: {
    height: pxToRem(60),
  },
  label: {
    fontWeight: 700,
  },
  dottedLine: {
    borderBottom: '2px dashed #6297FF',
    [breakpoint.down('sm')]: {
      borderBottom: 'unset',
    },
  },
  rightLine: {
    marginRight: `-${spacing(5)}`,
  },
  leftLine: {
    marginLeft: `-${spacing(5)}`,
  },
});

const content: {
  label: string,
  details: string,
}[] = [
  {
    label: 'Upload your existing materials',
    details: 'Think your website, product & service lists, pricing, event details, logos & branding, — either from your current platform or anywhere else they live.',
  },
  {
    label: 'We\'ll migrate them into the Bizwise system',
    details: 'Once you submit your files, our team will carefully migrate them into your Bizwise account.',
  },
  {
    label: 'We\'ll notify you when they\'re done',
    details: 'Expect an email from us when your materials are ready. We\'ll then seamlessly launch you.',
  },
];

const HowItWorks: React.FC = () => {
  const classes = useStyles();
  return (
    <SectionContainer className={classes.container}>
      <div className="ml-auto mr-auto">
        <Typography color="blue" variant="h2" underline centered>
          How does the free account migration work?
        </Typography>
      </div>
      <div className={classes.grid}>
        {content.map(({ label, details }, i) => (
          <div className="flex flex-col items-center" key={label}>
            <div className="flex flex-row items-center justify-center mb-6 desktop:mb-8 w-full">
              <div className={cx('flex-grow', i !== 0 && classes.dottedLine, classes.leftLine)} />
              <div className="rounded-[50%] bg-blue flex justify-center items-center w-[84px] h-[84px]">
                <Typography variant="h3" color="white">
                  {i + 1}
                </Typography>
              </div>
              <div className={cx('flex-grow', i !== content.length - 1 && classes.dottedLine, classes.rightLine)} />
            </div>
            <Typography variant="h3" color="blue" centered className={classes.label}>
              {label}
            </Typography>
            <Typography variant="p2" color="blackish" centered className="mt-2">
              {details}
            </Typography>
          </div>
        ))}
      </div>
    </SectionContainer>
  );
};

export default HowItWorks;
