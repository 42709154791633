import React from 'react';
import { createUseStyles } from 'react-jss';
import { assetLink } from 'src/utils';
import { spacing, breakpoint, pxToRem } from 'src/theme';
import SectionContainer from 'src/sections/shared/SectionContainer';
import Button from 'src/components/core/Button';
import Typography from 'src/components/core/Typography';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    marginTop: spacing(10),
    [breakpoint.down('sm')]: {
      width: '100%',
    },
  },
});

const GetStartedSection: React.FC = () => {
  const classes = useStyles();
  return (
    <SectionContainer
      className={classes.container}
      backgroundShape={10}
      background="light-blue"
      backgroundShapeBlendMode="soft-light"
    >
      <img
        src={assetLink('PeopleIconAlt.svg')}
        alt="People Icon"
        className="mb-10"
        style={{ width: pxToRem(120) }}
      />
      <Typography className="mb-4" variant="p1" color="blue" centered>
        Members who use account migration are transferred over within a week and
      </Typography>
      <Typography variant="h2" color="blue" underline centered>
        Feel more prepared for the next big client.
      </Typography>
      <Button
        variant="filled"
        color="teal"
        href="/register/"
        className={classes.button}
      >
        Start Free Trial
      </Button>
    </SectionContainer>
  );
};

export default GetStartedSection;
