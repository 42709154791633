import { useLayoutEffect, useRef, useState } from 'react';

export default function useQuestion() {
  const [isExpanded, setExpanded] = useState(false);
  const [style, setStyle] = useState<React.CSSProperties>({});
  const accordionRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const element = accordionRef.current;
    if (isExpanded) {
      const height = element?.scrollHeight;
      setStyle({ height });
    } else {
      setStyle({});
    }
  }, [isExpanded]);

  function expandAnswer() {
    setExpanded(!isExpanded);
  }

  return {
    isExpanded,
    accordionRef,
    style,
    expandAnswer,
  };
}
